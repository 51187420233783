.header {
  background: #fff !important;
  padding-top: 2vh;
  padding-bottom: 2vh;
  box-shadow: none;
  border-bottom: 1px solid #ddd;
  position: fixed;
}

.header-container {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
}

.header-namespace {
  color: #000;
  margin-left: 20px !important;
  font-size: 20px !important;
  text-transform: uppercase;
}

@media screen and (max-width: 460px) {
  .header-namespace {
    font-size: 15px !important;
  }
}

.deskopt-navigation {
  color: #000 !important;
  margin-left: 25px !important;
  text-transform: uppercase;
  font-weight: 700;
  transition: 300ms;
  cursor: pointer;
}

.deskopt-navigation:nth-child(1) {
  margin-left: 0px !important;
}
