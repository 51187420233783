@keyframes fadeIn {
  from {
    transition: 300ms;
    opacity: 0;
    transform: translateY(-2vh);
  }
  to {
    opacity: 1;
    transform: translateY(0vh);
  }
}

.fadeIn {
  animation: 700ms fadeIn;
}
