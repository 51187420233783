.course-container {
  min-height: 33vh;
}

.course-container-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

.course-item {
  width: 370px;
  height: 250px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
}

.course-item img {
  height: 100%;
}

.course-item-overlay {
  width: 100%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.434);
  transition: 300ms;
  cursor: pointer;
}

.course-item-overlay:hover {
  background: #b22a2aee;
}

.course-item-overlay-item {
  height: 50%;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  text-transform: uppercase;
  font-weight: 700 !important;
  transition: 450ms;
}

.course-item-overlay:hover .course-item-overlay-item {
  transform: translateY(-100%);
}

@media screen and (max-width: 1187px) {
  .course-item {
    width: calc(50vw - 35px);
    height: auto;
  }
  .course-item img {
    width: 100%;
  }
}

@media screen and (max-width: 802px) {
  .course-item {
    width: 100%;
    height: auto;
  }
  .course-item img {
    width: 100%;
  }
}
