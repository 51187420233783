.headings-container {
  width: 1240px;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.headings-container-margin {
  justify-content: center;
  margin-top: 15vh;
}

@media screen and (max-width: 424px) {
  .headings-container {
    flex-direction: column;
  }
}

.heading-text {
  color: #000;
  text-transform: uppercase;
  font-weight: 700 !important;
  text-align: center;
}

.toggler-label {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  margin-right: 5px;
}
