.footer-container {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 7vh;
  padding-bottom: 5vh;
  box-sizing: border-box;
}

@media screen and (max-width: 760px) {
  .footer-container {
    flex-direction: column;
  }
}

.footer-container-urls {
  display: flex;
  gap: 2px;
  flex-direction: row;
  margin-bottom: 0px;
  text-align: center;
}

.footer-container-urls-item {
  color: #000 !important;
  margin-left: 25px !important;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  transition: 300ms;
  cursor: pointer;
}

.footer-container-urls-item:nth-child(1) {
  margin-left: 0 !important;
}

@media screen and (max-width: 760px) {
  .footer-container-urls {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .footer-container-urls-item {
    color: #000;
    margin-left: 0 !important;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    transition: 300ms;
    cursor: pointer;
  }
}

.coppyright-text {
  color: #000 !important;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  transition: 300ms;
  cursor: pointer;
}

.coppyright-text-url {
  color: #000 !important;
}
