.heading-container {
  width: 100vw;
  height: 50vh;
  position: relative;
  margin-top: 10vh;
}

.heading-container-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url(../images/bgimg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@media screen and (max-width: 640px) {
  .heading-container-image {
    background-position: 50% 50%;
  }
}

.heading-container-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(178, 42, 42, 0.325) 0%,
    rgba(140, 19, 19, 0.224) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  box-sizing: border-box;
}

.heading-container-heading-text {
  text-transform: uppercase;
  font-weight: 700 !important;
  color: #fff;
  text-align: center;
  padding: 0px 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 540px) {
  .heading-container-heading-text {
    font-size: 30px !important;
  }
}

.heading-container-heading-text span {
  color: #b22a2a;
}

.heading-container-description-text {
  color: #fff;
  text-align: center;
  padding: 0px 10px;
  box-sizing: border-box;
}
