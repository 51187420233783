body {
  overflow-x: hidden;
}

.form-logo {
  width: 80%;
}

.textarea textarea {
  min-height: 40vh;
}

.separating-bar {
  position: relative;
  width: 45px;
  height: 7px;
  background: #b22a2a;
}

.hover-red-color:hover {
  color: #b22a2a !important;
}

.hover-button-color:hover {
  background: #8a1414 !important;
}

.legacy-content {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.legacy-content h2 {
  text-align: center;
}

.not-found {
  font-size: 80px;
  margin: 0;
}

.not-found-description {
  font-size: 20px;
  text-align: center;
}
